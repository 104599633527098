import React, { useState } from 'react';
import './Header.css';
import Button from '../Button/Button';
import { HashLink } from 'react-router-hash-link';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="navbar">
      <div className="left-container">
        <a className='nav__p nav__name' href="/">Robert Bari</a>
      </div>
      <div className="center-container">
        <a className='nav__p' href='/About' onClick={handleButtonClick}>About</a>
        <HashLink to='/#journey' style={{ textDecoration: 'none', marginTop: '0.6rem' }}>
          <a className='nav__p'>Work</a>
        </HashLink>
        <Button hashLinkTo='/#contact' label='Contact Me!' />
      </div>
      <div className="right-container">
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? (
            <img src="/close.png" alt="Close Icon" className='close-button' />
          ) : (
            <img src="/hamburger.png" alt="Hamburger Icon" className='hamburger-button' />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="mobile-menu">
          <a className='nav__p' href='/' onClick={handleButtonClick}>Home</a>
          <a className='nav__p' href='/About' onClick={handleButtonClick}>About</a>
          <HashLink to='/#journey'>
            <p className='nav__p'>Work</p>
          </HashLink>
          <HashLink to='/#contact'>
            <p className='nav__p'>Contact Me!</p>
          </HashLink>
        </div>
      )}
    </div>
  );
};

export default Navbar;
