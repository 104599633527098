import React from 'react'
import Hero from '../../components/Hero/Hero'
import TechStack from '../../components/TechStack/TechStack'
import {AboutIntroduction} from '../../components/AboutIntroduction/AboutIntroduction'
import {Skills} from '../../components/Skills/Skills'
import {techStackIcons} from "../../utils/projectData";
import ScrollBar from "../../components/ScrollBar/ScrollBar";
import ScrollButton from "../../components/ScrollButton/ScrollButton";

const About = () => {
    return (
        <>
            <ScrollBar />
            <ScrollButton />
            <div className="page-cont">
                <Hero
                    firstLine="My name is"
                    secondLine="Robert Bari"
                    thirdLine="I'm a Software Developer"
                    link='about/#about'
                />
                <AboutIntroduction/>
                <Skills/>
            </div>
        </>

    )
}

export default About