import React from 'react';
import Button from '../Button/Button';
import "./Hero.css";
import Skewed from '../Skewed/Skewed';

interface HeroProps {
    firstLine: string;
    secondLine: string;
    thirdLine: string;
    additionalLine?: string;
    link?: string;
}

const Hero: React.FC<HeroProps> = ({firstLine, secondLine, thirdLine, additionalLine, link}) => {

    return (
        <div id='hero' className='hero main-section'>
            <div className='hero__left'>
                <h3 className='text-light'>{firstLine}</h3>
                <h1>{additionalLine}</h1>
                <h1>{secondLine}</h1>
                <h5 className='hero__subheading'>{thirdLine}</h5>
                <div className='button-container'>
                    <Button hashLinkTo={`/${link || "#journey"}`} label='Find out more!'/>
                </div>
            </div>
            <div className='hero__right'>
                <Skewed>
                    <img src={require("../../assets/rob-web.png")} alt="Guy"/>
                </Skewed>
            </div>
        </div>
    )
}

export default Hero;
