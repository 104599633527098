import { ButtonSecondary } from "../ButtonSecondary/ButtonSecondary";
import Skewed from '../Skewed/Skewed';
import React from 'react';
import './ItemCard.css'
import {allProjectsData, ProjectData} from "../../utils/projectData";

interface ItemCardProps {
    heading: string,
    id: string
}
export const ItemCard : React.FC<ItemCardProps> = ({heading, id}) => {

    const projectData = allProjectsData[id] as ProjectData;
    const handleClick = () => {
        window.location.href =`/project/${id}`
    }

    return (
        <div className="item-card__cont">
                <div className="item-card__header">
                    <h3 onClick={handleClick}>{heading}</h3>
                    <ButtonSecondary label="see more" onClick={handleClick}/>
                </div>
            <Skewed>
                <div className="item-card__image__cont">
                    <img className="item-card__image" onClick={handleClick} src={require(`../../assets/data/${id}/${projectData.img[projectData.cover || 0]}`)} />
                </div>
            </Skewed>
        </div>
    )
}