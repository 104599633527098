import { ReactNode, useState } from "react"

interface Props {
    children: ReactNode
}

const Skewed = (props: Props) => {

    const [style, setStyle] = useState({});
    const [firstHover, setFirstHover] = useState(true);


    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (firstHover) {
            setFirstHover(false); // Set firstHover to false after the first hover
        }

        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const x = e.clientX - rect.left - (rect.width / 2);
        const y = e.clientY - rect.top - (rect.height / 2);

        // Calculate rotation
        const rotateX = -(y / rect.height) * 10; // Rotation around X-axis
        const rotateY = (x / rect.width) * 10; // Rotation around Y-axis

        setStyle({
            transform: `perspective(500px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
            transition: firstHover ? 'transform 0.5s' : 'transform 0.1s'
        });
    };

    const handleMouseLeave = () => {
        setStyle({ transition: 'transform 0.5s' });
        setFirstHover(true);
    };

    return (
        <div
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            className="skewed-cont">
            <div
                style={style}
                className="skewed">
                {props.children}
            </div>
        </div>
    )
}

export default Skewed