import React from 'react';
import './Button.css';
import { HashLink } from 'react-router-hash-link';

interface Props {
    label?: string;
    action?: () => void;
    hashLinkTo?: string;
}

const Button: React.FC<Props> = ({ label = "", action, hashLinkTo }) => {
    const buttonContent = (
        <div className='button'>
            <div className='button__background'></div>
            <p>{label}</p>
        </div>
    );

    if (hashLinkTo) {
        return (
            <HashLink smooth to={hashLinkTo} style={{ textDecoration: 'none' }}>
                {buttonContent}
            </HashLink>
        );
    }

    return (
        <div onClick={action}>
            {buttonContent}
        </div>
    );
}

export default Button;
