import { useEffect, useRef, useState } from 'react';
import './odometer.css'

type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

interface Props {
    value: number
}

const Odometer = (props: Props) => {

    const strValue: string = '0' + props.value;


    return (
        <div className="odometer">
            {Array.from(strValue).map((figure, key) =>
            <OdometerColumn value={figure as unknown as Digit} key={key}/>
            )}
        </div>
    );
};

interface ColumnProps {
    value: Digit
}

const OdometerColumn = (props: ColumnProps) => {

    
    return (
        <div style={{top: `-${props.value}em`}} className='odometer__column'>
            <p>0</p>
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p>4</p>
            <p>5</p>
            <p>6</p>
            <p>7</p>
            <p>8</p>
            <p>9</p>
        </div>
    )
}


export default Odometer