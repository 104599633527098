import EmailForm from '../EmailForm/EmailForm'
import './Contact.css'


const Contact = () => {

    return (
        <div id='contact' className='main-section'>
            <div className='text-block'>
                <h5>Don't hesitate to</h5>

                <h3 className='contact-me'>Contact Me</h3>

                </div>
                <div className='text-block'>
                <EmailForm />
            </div>
        </div>
    )
}

export default Contact;