import { useState, useEffect, useRef, useCallback } from 'react';

const useScroll = (options?: IntersectionObserverInit) => {
    const [elementsInView, setElementsInView] = useState<Set<string>>(new Set());
    const observer = useRef<IntersectionObserver | null>(null);
  
    useEffect(() => {
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          const id = entry.target.getAttribute('id');
          if (id) {
            setElementsInView(prev => {
              const newSet = new Set(prev);
              if (entry.isIntersecting) {
                newSet.add(id);
              } else {
                newSet.delete(id);
              }
              return newSet;
            });
          }
        });
      }, {
        threshold: 0.1 // Customize this value as needed
      });
  
      // Observing elements
      const observedElements = document.querySelectorAll('.main-section');
      observedElements.forEach(element => observer.current?.observe(element));
  
      return () => {
        observedElements.forEach(element => observer.current?.unobserve(element));
      };
    }, []);

  return { elementsInView };
};

export default useScroll;
