import { useState, useEffect } from 'react';
import './ScrollButton.css';

const ScrollButton = () => {
    const [showScrollUpButton, setShowScrollUpButton] = useState(false);

    const handleScroll = () => {
        const scrolled = window.scrollY;
        setShowScrollUpButton(scrolled > 200);
    };

    const handleScrollUpButtonClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='scroll-button-container' onClick={handleScrollUpButtonClick}>
            {showScrollUpButton && (
                <div className='scroll-up-button'>
                    Scroll Up
                </div>
            )}
            {showScrollUpButton && <div className='arrow'></div>}
        </div>
    );
};

export default ScrollButton;
