import './SidebarLogos.css';
interface Props {
    gitLink: string,
    linkedinLink: string,
}
export const SidebarLogos = (props: Props) => {
    const handleClick = (url: string) => {
        window.open(url);
    }
    return (
        <div className="sidebar-logos">
            <div className="sidebar-logos--container" onClick={()=>handleClick(props.gitLink)}>
                <img src={require('../../assets/logo-github.png')} />
            </div>
            <div className="sidebar-logos--container" onClick={()=>handleClick(props.linkedinLink)}>
                <img src={require('../../assets/logo-linkedin.png')} />
            </div>
        </div>
    )
}