import ScrollBar from "../../components/ScrollBar/ScrollBar";
import ScrollButton from "../../components/ScrollButton/ScrollButton";
import './Project.css'
import {useLocation} from "react-router";
import {allProjectsData, ProjectData} from "../../utils/projectData";
import {ReactNode} from "react";
import TechStack from "../../components/TechStack/TechStack";
import {ButtonSecondary} from "../../components/ButtonSecondary/ButtonSecondary";

const Project = () => {

    const location = useLocation();
    const projectId = location.pathname.split('/').pop() || '1';
    const projectData = allProjectsData[projectId] as ProjectData;

    return (
        <>
            <ScrollBar/>
            <ScrollButton/>

            <div className='page-cont'>
                <div id="header" className="project-header main-section">
                    <h3>{projectData.name}</h3>
                    <h6>{projectData.date}</h6>
                    <div className="project-header--image-cont">
                        <img src={require(`../../assets/data/${projectId}/${projectData.img[projectData.cover || 0]}`)}/>
                    </div>
                </div>
                <p className="project-description">{projectData.description}</p>
                <div id="details" className="project-details main-section">
                    <div>
                        <p className="project-details--header">Role:</p>
                        <p>{projectData.role}</p>
                    </div>
                    <div>
                        <p className="project-details--header">Client:</p>
                        <p>{projectData.customer}</p>
                    </div>
                    <div>
                        <p className="project-details--header">Frontend:</p>
                        <p>{projectData.front}</p>
                    </div>
                    {projectData.back &&
                        <div>
                            <p className="project-details--header">Backend:</p>
                            <p>{projectData.back}</p>
                        </div>}
                    {projectData.db &&
                        <div>
                            <p className="project-details--header">Database:</p>
                            <p>{projectData.db}</p>
                        </div>}
                    <div>
                        <p className="project-details--header">UI/UX design:</p>
                        <p>{projectData.ui}</p>
                    </div>
                    <div>
                        <p className="project-details--header">Version control:</p>
                        <p>{projectData.vc}</p>
                    </div>
                    <div>
                        <p className="project-details--header">Project management:</p>
                        <p>{projectData.pm}</p>
                    </div>
                </div>
                <div className="project-details--buttons-container">
                    {projectData.repo2 ?
                        <><ButtonSecondary label={"View Frontend Repository"}
                                           onClick={() => window.open(projectData.repo1)}/>
                            <ButtonSecondary label={"View Backend Repository"}
                                             onClick={() => window.open(projectData.repo2)}/></>
                        :
                        <ButtonSecondary label={"View Repository"}
                                         onClick={() => window.open(projectData.repo1)}/>}
                </div>
                <div id="image-cont" className="project--image-cont main-section">
                    {projectData.img.map((img) => {
                            if (img.includes("Mobile")) {
                                return;
                            }
                            return (<img src={require(`../../assets/data/${projectId}/${img}`)}/>)
                        }
                    )}
                    <div className="mobile-img__cont">
                        {projectData.img.map((img) => {
                                if (img.includes("Mobile")) {
                                    return (<img src={require(`../../assets/data/${projectId}/${img}`)}/>)
                                }
                                return;
                            }
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Project