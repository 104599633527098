import data from '../assets/data/data.json';
export interface ProjectData {
    "name": string
    "short"?: string
    "date": string
    "description": string
    "role": string
    "customer": string
    "front": string
    "back"?: string
    "db"?: string
    "ui": string
    "pm": string
    "vc": string
    "repo1": string
    "repo2"?: string
    "img": string[]
    "cover"?: number
}
interface AllProjectsData {
    [key: string]: ProjectData;
}

export const techStackIcons: string[] = [
    'Git',
    'Github',
    'Nextjs',
    'Axios',
    'Firebase',
    'Typescript',
    'Node',
    'Expo',
    'Express',
    'Json',
    'React',
    'Restapi',
];

export const allProjectsData = data as AllProjectsData;