import Hero from '../../components/Hero/Hero'
import { IntroSection } from '../../components/IntroSection/IntroSection'
import Contact from '../../components/Contact/Contact';
import "./Home.css"
import Journey from '../../components/Journey/Journey';
import ScrollBar from '../../components/ScrollBar/ScrollBar';
import ScrollButton from '../../components/ScrollButton/ScrollButton';

const Home = () => {



  return (
    <>
      <ScrollBar />
      <ScrollButton />

      <div className='page-cont'>
        <Hero
          firstLine="I am a"
          secondLine="Developer"
          thirdLine="Based in Copenhagen"
        />
        <IntroSection />
        <Journey />
        <Contact />
      </div>
    </>
  )
}

export default Home