import './EmailForm.css';
import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import Button from "../Button/Button";
import {toast} from "react-toastify";

interface EmailForm {
    email: string,
    name: string,
    message: string
}

const FormComponent = () => {

    const eEmail = {email: '', name: '', message: ''};
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [email, setEmail] = useState(eEmail);
    const [errors, setErrors] = useState(eEmail);
    const [honeyPot, setHoneyPot] = useState('');

    const validate = (field: keyof EmailForm, value: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let errorMessage = '';
        if(field === 'email') {
            errorMessage = value && regex.test(value) ? '' : 'Please enter a valid email';
        } else {
            errorMessage = value ? '' : `Your ${field} cannot be empty`;
        }
        return errorMessage;
    }

    const handleBlur = (field: keyof EmailForm, value: string) => {
        const newError = {...errors};
        const errorMessage = validate(field, value);
        newError[field] = errorMessage;
        setErrors(newError);
        if (!errorMessage) {
            const newEmail = {...email, [field]: value};
            setEmail(newEmail);
        }
    }

    const sendEmail = () => {
        let allValid = true;
        const newErrors = {...errors};
        (Object.keys(email) as (keyof EmailForm)[]).forEach(key => {
            const error = validate(key, email[key]);
            newErrors[key] = error;
            if (error) allValid = false;
        });
        setErrors(newErrors);

        if (allValid && honeyPot === '') {
            const toastId = toast.loading("Sending email...");
            emailjs.send(
                process.env.REACT_APP_EMAIL_SERVICE_ID || '',
                process.env.REACT_APP_EMAIL_TEMPLATE_ID || '',
                email,
                process.env.REACT_APP_EMAIL_USER_ID || '')
                .then((result) => {
                    console.log(result.text);
                    setEmailSent(true);
                    setEmail(eEmail);
                    toast.update(toastId, {
                        render: "Email sent!",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                        closeButton: true
                    });
                    (document.getElementById('form1') as HTMLFormElement).reset();
                }).catch((error) => {
                toast.update(toastId, {
                    render: "Something went wrong :(",
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                    closeButton: true
                });
                console.log(error.text);
            });
        }
    };

    return (
        <>
            <div className="form-container">
                <form id="form1">
                    <div className="input-group">
                        <div className={"form-input " + (errors.name ? "input-error" : "") }>
                            <input type="text" id="name" name="name" placeholder=" " onBlur={
                                (e) => handleBlur('name', e.currentTarget.value)}/>
                            <label className='form-input-label' htmlFor="name">Name</label>
                            <p>{errors.name || "P"}</p>
                        </div>

                        <div className={"form-input " + (errors.email ? "input-error" : "") }>
                            <input type="email" id="email" name="email" placeholder=" " onBlur={
                                (e) => handleBlur('email', e.currentTarget.value)}/>
                            <label className='form-input-label' htmlFor="email">Email</label>
                            <p>{errors.email || "P"}</p>
                        </div>

                    </div>
                    <div className={"form-input " + (errors.message ? "input-error" : "") }>
                        <textarea className='no-resize' id="message" name="message" placeholder=" " onBlur={
                            (e) => handleBlur('message', e.currentTarget.value)}></textarea>
                        <label className='form-input-label' htmlFor='message'>Message</label>
                        <p>{errors.message || "P"}</p>
                    </div>
                    <input type='text' hidden={true} onChange={() => {
                        setHoneyPot("BOT");
                        console.log("BOT!");
                    }}/>

                    <div className='send-button_container'>
                        <Button action={sendEmail} label='Send'/>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FormComponent;
