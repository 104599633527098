import './IntroSection.css'
import {ButtonSecondary} from "../ButtonSecondary/ButtonSecondary";

export const IntroSection = () => {
    return (
        <div id='intro' className="container intro-section">
            <p>During my education,<span className="highlight-text"> professional </span>and hobby projects I gained
                experience with building
                applications utilising a variety of technologies
                , while focusing on<span className="highlight-text"> responsivity, </span>
                accessibility, maintainability and<span className="highlight-text"> performance </span>as well. I
                built a foundation in<span className="highlight-text"> UI/UX design, </span>version-control,
                <span className="highlight-text"> CI/CD, </span>unit<span className="highlight-text"> testing </span>and
                <span className="highlight-text"> Agile </span>methodologies.
                My colorful background solidified my<span className="highlight-text"> problem-solving </span>and<span
                    className="highlight-text"> communication </span>skills,
                my<span className="highlight-text"> attention-to-detail, </span>as well as my ability to work both
                independently
                and in a<span className="highlight-text"> team </span>environment.</p>
            <ButtonSecondary label="More" onClick={() => window.location.href = "/about"}/>
        </div>
)
}