import './AboutIntroduction.css'
import {ButtonSecondary} from "../ButtonSecondary/ButtonSecondary";
export const AboutIntroduction = () => {
    return (
        <div id="about" className="about-intro-section main-section">
            <h4 className="about-title">Education</h4>
            <p className="about-subtitle">08/2021-01/2024</p>
            <h6 className="about-secondary-title">Computer Science Academy Profession Degree, Copenhagen School of
                Design & Technology</h6>
            <p className="about-text">I have learned programming, software development and IT architecture foundations,
                version control,
                full-stack web and mobile development with a range of technologies, unit testing, debugging, best
                practises,
                UI/UX design, agile project management methodologies and the basics of business analysis.</p>
            <h4 className="about-title">Additional work experience</h4>
            <p className="about-subtitle">2012-present</p>
            <h6 className="about-secondary-title">A great variety of jobs in Denmark, Iceland and Hungary </h6>
            <p className="about-text">Having worked with people from all walks of life, often in extreme environments,
                I picked up strong communication and collaboration skills. Both as a team member and leader, I always found
                it important to optimise the workflow and adhere to quality standards. These experiences also developed my
                technical comprehension, my ability to adapt, learn fast  and find solutions to newly met challenges. Throughout the years I have worked as a:
                warehouseman, farm hand, mason, carpenter, driver, lumberjack, mechanic, nurse and a waiter.</p>
            <h4 className="about-title">About me</h4>
            <p className="about-text">I have been playing the drums for over 10 years, and I am the drummer of the band
                ‘PERKUPA’, so music is
                my greatest passion besides coding. My other passions: cinema, literature, natural science, photography,
                swimming, hiking, video games, board games and puzzles. I graduated from a bilingual high school that
                granted me excellent English skills, and I attended medical school for years, where my attention to
                detail, and my analytical, pattern recognition and self-motivating skills have developed.</p>
            <div style={{alignSelf: "center"}}>
                <ButtonSecondary label="My band on Spotify" onClick={() => window.open("https://open.spotify.com/artist/798mj8ga5ZFHGIMDBdZkXa")}/>
            </div>
        </div>
    )
}